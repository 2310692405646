/* ======================================================================== */
/* 62. SectionVideo */
/* ======================================================================== */
const SectionVideo = function ($scope) {

	const $target = $scope.find('.section-video');

	if (!$target.length) {
		return;
	}



}
